import Modernizr from "../vendor/custom-modernizr";
import $ from "jquery";

// Object Fit Fallback
class ObjectFitPoly {

    constructor(objFitAttr = "data-object-fit-type", objPosAttr = "data-object-fit-position") {
        this.objFitAttr = objFitAttr;
        this.objPosAttr = objPosAttr;
    }

    fill() {

        const isSupported = window.Modernizr.objectfit || Modernizr.objectFit;

        if (!isSupported) {
            $(`[${this.objFitAttr}]`).each((i, el) => {
                const $img = $(el);
                const imgSrc = $img.attr("src");
                const fitType = $img.attr(`${this.objFitAttr}`);
                const fitPos = $img.attr(`${this.objPosAttr}`) ? $img.attr(`${this.objPosAttr}`) : "center center";

                $img.wrap(`<div class='object-fit-wrapper' style='background: transparent url("${imgSrc}") no-repeat ${fitPos}/ ${fitType};'></div>`);

                $img.css({
                    opacity: 0
                });
            });
        }

    }

}

export default ObjectFitPoly;
