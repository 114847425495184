/**
 * Global functions are loaded here
 */
import SpriteLoader from "../modules/svg-sprite-loader";
import ObjectFitPoly from "../polyfills/object-fit";
import { megaMenu } from "../modules/mega-menu";
import stickybits from "stickybits";

const mashbo = {

    init() {

        const elements = {
            body: document.body,
            navBtnOpen: document.getElementById("NavOpenButton"),
            navBtnClose: document.getElementById("NavCloseButton"),
            filterButtons: document.querySelectorAll(".team__filter"),
            filterPanels: document.querySelectorAll(".team__item")
        };

        // Append SVG Sprite
        // no-undef disabled as wpUrls is undefined until injected by Wordpress. SM
        // eslint-disable-next-line no-undef
        // const sprite = new SpriteLoader(`${wpUrls.themeFolder}/assets/images/sprite.svg `, elements.body);

        // sprite.append();

        // Object fit polyfill
        const objectFit = new ObjectFitPoly();

        objectFit.fill();

        // Mega Menu
        megaMenu();

        // sticky polyfil
        stickybits(".feature-image--vertical", { stickyBitStickyOffset: 80 });
    }
};

mashbo.init();
